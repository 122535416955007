var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { onload: _vm.controlActiveTab() } },
    [
      _c(
        "v-app-bar",
        {
          staticClass: "app-toolbar",
          attrs: { app: "", "clipped-left": "", fixed: "", color: "primary" },
        },
        [
          _c(
            "v-container",
            { staticClass: "py-0 fill-height" },
            [
              _c("v-app-bar-nav-icon", {
                staticClass: "hidden-lg-and-up",
                staticStyle: { color: "textColor !important" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.sidebar = !_vm.sidebar
                  },
                },
              }),
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm.isEmbeded != "true"
                  ? _c("img", {
                      staticClass: "logo hidden-xs-only",
                      attrs: {
                        src: require("@/assets/logo.png"),
                        height: "24px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.disparar({ title: "" })
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _c(
                "v-toolbar-items",
                { staticClass: "hidden-md-and-down app-toolbar-links" },
                [
                  _vm._l(_vm.returnMenus, function (item) {
                    return [
                      !item.children && _vm.hasMenuAccess(item.roles)
                        ? _c(
                            "v-btn",
                            {
                              key: "toolbar_" + item.title,
                              class:
                                item.title === "War Smart Solutions"
                                  ? "my-2 mr-2 px-4 py-0 wssButton textColor--text"
                                  : item.path === "/home"
                                  ? "mx-2 normalButtons textColor--text "
                                  : "normalButtons textColor--text",
                              style: _vm.active
                                ? "backgroundColor: activeOne; margin-top:0px !important; height:64px !important "
                                : item.title === "War Smart Solutions"
                                ? "border-radius:4px !important; height:50px !important "
                                : "border-radius:0px !important",
                              attrs: {
                                id: item.title,
                                to: item.path,
                                dark: "",
                                text: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.disparar(item)
                                },
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(item.title) + " "),
                              _c("v-icon", [_vm._v(_vm._s(item.icon))]),
                            ],
                            1
                          )
                        : _vm.hasMenuAccess(item.roles)
                        ? _c(
                            "v-menu",
                            {
                              key: `toolbar_${item.title}`,
                              attrs: {
                                transition: "slide-y-transition",
                                "open-on-hover": "",
                                "offset-y": "",
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                class:
                                                  item.title === "Analytics"
                                                    ? "my-2 mr-2 px-4 py-0 wssButton textColor--text"
                                                    : "normalButtons textColor--text",
                                                attrs: {
                                                  id: item.title,
                                                  text: "",
                                                  dark: "",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { right: "", dark: "" },
                                              },
                                              [_vm._v("mdi-chevron-down")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            _vm._l(item.children, function (childrenItem, i) {
                              return _c(
                                "v-list",
                                {
                                  key: i,
                                  attrs: {
                                    id: childrenItem.title,
                                    to: childrenItem.path,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.disparar(item)
                                    },
                                  },
                                },
                                [
                                  !childrenItem.children &&
                                  _vm.hasMenuAccess(childrenItem.roles)
                                    ? _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            id: childrenItem.title,
                                            to: childrenItem.path,
                                            link: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(childrenItem.title)
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.hasMenuAccess(childrenItem.roles)
                                    ? _c(
                                        "v-list-group",
                                        {
                                          key: i + "_child",
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function () {
                                                  return [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.capitalize(
                                                            childrenItem.title
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.groupOpened,
                                            callback: function ($$v) {
                                              _vm.groupOpened = $$v
                                            },
                                            expression: "groupOpened",
                                          },
                                        },
                                        _vm._l(
                                          childrenItem.children,
                                          function (subItem) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: subItem.title,
                                                attrs: {
                                                  id: subItem.title,
                                                  to: subItem.path,
                                                  link: "",
                                                },
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(_vm._s(subItem.title)),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "50%" },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      return _vm.close()
                    },
                  },
                  model: {
                    value: _vm.returnMessage,
                    callback: function ($$v) {
                      _vm.returnMessage = $$v
                    },
                    expression: "returnMessage",
                  },
                },
                [
                  _vm.returnMessage
                    ? _c(
                        "v-alert",
                        {
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { type: "info" },
                        },
                        [
                          _vm._v(_vm._s(_vm.returnMessage) + " "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.close()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "material-symbols-outlined v-btn theme--dark",
                                },
                                [_vm._v("close")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-spacer"),
              _c("HubspotWidget"),
              _vm.adminMenus[0].value && _vm.authorities.includes("ROLE_ADMIN")
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        bottom: "",
                        right: "",
                        transition: "slide-y-transition",
                        "offset-y": "",
                        "close-on-content-click": false,
                        "open-on-hover": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    { attrs: { icon: "", small: "" } },
                                    on
                                  ),
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "material-symbols-outlined v-btn theme--dark button-size notranslate",
                                      },
                                      [_vm._v("settings")]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1401657324
                      ),
                    },
                    _vm._l(_vm.menuSettings, function (item, i) {
                      return _c(
                        "v-list",
                        { key: i, staticClass: "pt-0 pb-0" },
                        [
                          !item.children
                            ? _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    id: item.title,
                                    to: item.path,
                                    link: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.title)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-list-group",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: item.active,
                                    callback: function ($$v) {
                                      _vm.$set(item, "active", $$v)
                                    },
                                    expression: "item.active",
                                  },
                                },
                                _vm._l(item.children, function (subItem) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: subItem.title,
                                      attrs: {
                                        id: subItem.title,
                                        to: subItem.path,
                                        link: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        {
                                          staticStyle: {
                                            "margin-left": "16px",
                                          },
                                        },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(subItem.title)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.adminMenus[1].value
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        to: "/help-desk/chamados",
                        dark: "",
                        icon: "",
                        small: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "material-symbols-outlined v-btn theme--dark button-size notranslate",
                        },
                        [_vm._v("help")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.adminMenus[2].value
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        bottom: "",
                        right: "",
                        transition: "slide-y-transition",
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c("v-list-item-avatar", _vm._g({}, on), [
                                  _c("img", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      id: "thumbnailmain",
                                      src: _vm.thumbnail,
                                    },
                                  }),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        778664767
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                id: "btnProfile",
                                to: "/profile",
                                link: "",
                              },
                            },
                            [_c("v-list-item-title", [_vm._v("Perfil")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { id: "btnSair", link: "" },
                              on: { click: _vm.logout },
                            },
                            [_c("v-list-item-title", [_vm._v("Sair")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.toastCertidoes.show
        ? _c("toast", {
            attrs: {
              "type-toast": _vm.toastCertidoes.type,
              message: _vm.toastCertidoes.message,
              "top-toast": _vm.toastCertidoes.topToast,
            },
            on: { closeToast: _vm.closeToastCertidoes },
          })
        : _vm._e(),
      _vm.toastTotalizers.show
        ? _c("toast", {
            attrs: {
              "type-toast": _vm.toastTotalizers.type,
              message: _vm.toastTotalizers.message,
              "top-toast": _vm.toastTotalizers.topToast,
            },
            on: { closeToast: _vm.closeToastTotalizers },
          })
        : _vm._e(),
      _vm.genericToast.show
        ? _c("toast", {
            attrs: {
              "type-toast": _vm.genericToast.type,
              message: _vm.genericToast.message,
              "top-toast": _vm.genericToast.topToast,
            },
            on: { closeToast: _vm.closeToast },
          })
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", "disable-resize-watcher": "" },
          model: {
            value: _vm.sidebar,
            callback: function ($$v) {
              _vm.sidebar = $$v
            },
            expression: "sidebar",
          },
        },
        [
          _c(
            "v-list",
            [
              _vm._l(_vm.returnMenus, function (item) {
                return [
                  item.children
                    ? _c(
                        "v-list-group",
                        {
                          key: "drawer_menu" + item.title,
                          attrs: { value: _vm.$route.path.includes(item.path) },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        _vm._l(item.children, function (itemInner) {
                          return _c(
                            "v-list-item",
                            {
                              key: "drawer_item_inner" + itemInner.title,
                              attrs: { to: itemInner.path, link: "" },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "pl-5" },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(itemInner.title)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "v-list-item",
                        {
                          key: "drawer_item" + item.title,
                          attrs: { to: item.path, link: "" },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.title)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "page-container", attrs: { fluid: "" } },
        [
          _c(
            "vue-page-transition",
            { attrs: { name: "fade-in-right" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }