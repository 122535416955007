import * as roles from './menuRoles';

const menu = [
  {
    title: '',
    path: '/home',
    icon: 'mdi-home-outline',
  },
  {
    title: 'Analytics',
    path: '/documents',
    roles: [...roles.downloads, ...roles.filesRead],
    children: [
      {
        title: 'Downloads',
        path: '/documents/download',
        roles: roles.downloads,
      },
      {
        title: 'Arquivos',
        path: '/documents/file',
        roles: roles.filesRead,
      },
    ],
  },
  {
    title: 'War Smart Solutions',
    path: '/war-smart-solutions/agentes-nocivos-e-aposentadoria-especial',
    roles: roles.system,
  },
  {
    title: 'eSocial',
    path: '/eSocial/',
    roles: [...roles.cadastros, ...roles.esocial, ...roles.cessao, ...roles.afastadosInativos, ...roles.cbo],
    children: [
      {
        title: 'Indicadores',
        path: '/eSocial/indicadores',
        roles: roles.esocial,
      },
      {
        title: 'trabalhador',
        path: '/eSocial/',
        roles: [...roles.esocial, ...roles.cessao, ...roles.afastadosInativos, ...roles.cbo],
        children: [
          {
            title: 'Todos',
            path: `/eSocial/trabalhador/todos`,
            roles: roles.esocial,
          },
          {
            title: 'Afastados e inativos',
            path: `/eSocial/trabalhador/afastados-e-inativos`,
            roles: roles.afastadosInativos,
          },
          {
            title: 'CBO',
            path: '/eSocial/trabalhador/cbo',
            roles: roles.cbo,
          },
        ],
      },
      {
        title: 'Rubricas',
        path: '/eSocial/rubricas',
        roles: roles.esocial,
      },
      {
        title: 'Empresas',
        path: '/eSocial/empresas',
        roles: roles.cadastros,
      },
      {
        title: 'Estabelecimentos',
        path: '/eSocial/estabelecimentos',
        roles: roles.esocial,
      },
    ],
  },
  {
    title: 'Certidões',
    roles: [...roles.certidoes, ...roles.crf],
    children: [
      {
        title: 'CND / CPEN',
        path: '/certidoes',
        roles: roles.certidoes,
      },
      {
        title: 'CRF',
        path: '/crf',
        roles: roles.crf,
      },
    ],
  },
  {
    title: 'e-CAC',
    path: '/ecac',
    roles: [...roles.ecac, ...roles.ecacMensagens],
    children: [
      {
        title: 'DCTF',
        path: '/ecac/dctf',
        roles: roles.ecac,
      },
      {
        title: 'Mensagens',
        path: '/ecac/mensagens',
        roles: roles.ecacMensagens,
      },
    ],
  },
  {
    title: 'Totalizadores',
    path: '/totalizadores',
    roles: roles.totalizadores,
  },
];

export default menu;
