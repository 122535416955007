<template>
  <v-app :onload="controlActiveTab()">
    <v-app-bar app clipped-left fixed class="app-toolbar" color="primary">
      <v-container class="py-0 fill-height">
        <v-app-bar-nav-icon
          class="hidden-lg-and-up"
          style="color: textColor !important"
          @click.stop="sidebar = !sidebar"
        />
        <router-link to="/home">
          <img
            v-if="isEmbeded != 'true'"
            :src="require('@/assets/logo.png')"
            height="24px"
            class="logo hidden-xs-only"
            @click="disparar({ title: '' })"
          />
        </router-link>
        <v-toolbar-items class="hidden-md-and-down app-toolbar-links">
          <template v-for="item in returnMenus">
            <v-btn
              v-if="!item.children && hasMenuAccess(item.roles)"
              :id="item.title"
              :key="'toolbar_' + item.title"
              :to="item.path"
              dark
              text
              @click="disparar(item)"
              :class="
                item.title === 'War Smart Solutions'
                  ? 'my-2 mr-2 px-4 py-0 wssButton textColor--text'
                  : item.path === '/home'
                  ? 'mx-2 normalButtons textColor--text '
                  : 'normalButtons textColor--text'
              "
              :style="
                active
                  ? 'backgroundColor: activeOne; margin-top:0px !important; height:64px !important '
                  : item.title === 'War Smart Solutions'
                  ? 'border-radius:4px !important; height:50px !important '
                  : 'border-radius:0px !important'
              "
            >
              {{ item.title }} <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
            <v-menu
              v-else-if="hasMenuAccess(item.roles)"
              :key="`toolbar_${item.title}`"
              transition="slide-y-transition"
              open-on-hover
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :id="item.title"
                  :class="
                    item.title === 'Analytics'
                      ? 'my-2 mr-2 px-4 py-0 wssButton textColor--text'
                      : 'normalButtons textColor--text'
                  "
                  text
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.title }}
                  <v-icon right dark>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list
                v-for="(childrenItem, i) in item.children"
                :id="childrenItem.title"
                :key="i"
                :to="childrenItem.path"
                @click="disparar(item)"
              >
                <v-list-item
                  v-if="!childrenItem.children && hasMenuAccess(childrenItem.roles)"
                  :id="childrenItem.title"
                  :to="childrenItem.path"
                  link
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ childrenItem.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-group v-else-if="hasMenuAccess(childrenItem.roles)" :key="i + '_child'" v-model="groupOpened">
                  <template v-slot:activator>
                    <v-list-item-title>{{ capitalize(childrenItem.title) }}</v-list-item-title>
                  </template>
                  <v-list-item
                    v-for="subItem in childrenItem.children"
                    :id="subItem.title"
                    :key="subItem.title"
                    :to="subItem.path"
                    link
                  >
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-menu>
          </template>
        </v-toolbar-items>
        <v-dialog v-model="returnMessage" max-width="50%" @keydown.esc="close()">
          <v-alert v-if="returnMessage" type="info" style="margin-bottom: 0px"
            >{{ returnMessage }}
            <v-btn icon @click="close()">
              <span class="material-symbols-outlined v-btn theme--dark">close</span>
            </v-btn></v-alert
          >
        </v-dialog>
        <v-spacer></v-spacer>
        <HubspotWidget />

        <v-menu
          v-if="adminMenus[0].value && authorities.includes('ROLE_ADMIN')"
          bottom
          right
          transition="slide-y-transition"
          offset-y
          :close-on-content-click="false"
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <v-btn icon small v-on="on">
              <span class="material-symbols-outlined v-btn theme--dark button-size notranslate">settings</span></v-btn
            >
          </template>
          <v-list v-for="(item, i) in menuSettings" :key="i" class="pt-0 pb-0">
            <v-list-item v-if="!item.children" :id="item.title" :to="item.path" link>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group v-else v-model="item.active">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="subItem in item.children"
                :id="subItem.title"
                :key="subItem.title"
                :to="subItem.path"
                link
              >
                <v-list-item-content style="margin-left: 16px">
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-menu>
        <v-btn v-if="adminMenus[1].value" to="/help-desk/chamados" dark icon small>
          <span class="material-symbols-outlined v-btn theme--dark button-size notranslate">help</span>
        </v-btn>

        <v-menu v-if="adminMenus[2].value" bottom right transition="slide-y-transition" offset-y>
          <template v-slot:activator="{ on }">
            <v-list-item-avatar v-on="on">
              <img id="thumbnailmain" :src="thumbnail" style="cursor: pointer" />
            </v-list-item-avatar>
          </template>
          <v-list>
            <v-list-item id="btnProfile" to="/profile" link>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item>

            <v-list-item id="btnSair" link @click="logout">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>
    <toast
      v-if="toastCertidoes.show"
      :type-toast="toastCertidoes.type"
      :message="toastCertidoes.message"
      :top-toast="toastCertidoes.topToast"
      @closeToast="closeToastCertidoes"
    ></toast>
    <toast
      v-if="toastTotalizers.show"
      :type-toast="toastTotalizers.type"
      :message="toastTotalizers.message"
      :top-toast="toastTotalizers.topToast"
      @closeToast="closeToastTotalizers"
    ></toast>
    <toast
      v-if="genericToast.show"
      :type-toast="genericToast.type"
      :message="genericToast.message"
      :top-toast="genericToast.topToast"
      @closeToast="closeToast"
    ></toast>
    <v-navigation-drawer v-model="sidebar" app disable-resize-watcher>
      <v-list>
        <template v-for="item in returnMenus">
          <v-list-group v-if="item.children" :key="'drawer_menu' + item.title" :value="$route.path.includes(item.path)">
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="itemInner in item.children"
              :key="'drawer_item_inner' + itemInner.title"
              :to="itemInner.path"
              link
            >
              <v-list-item-content class="pl-5">
                <v-list-item-title>{{ itemInner.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="'drawer_item' + item.title" :to="item.path" link>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-container class="page-container" fluid>
      <vue-page-transition name="fade-in-right">
        <router-view />
      </vue-page-transition>
    </v-container>
  </v-app>
</template>
<script>
import ThemeMixin from '@/mixins/Theme.vue';
import Toast from '@/router/views/commons/Toast.vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';

import { eventBus } from '../../main.js';
import HubspotWidget from './components/HubspotWidget.vue';
import menu from '@/assets/json/menu';

export default {
  name: 'AppLayout',
  isAdmin: false,
  components: {
    HubspotWidget: HubspotWidget,
    Toast,
  },

  mixins: [ThemeMixin],
  data() {
    return {
      isEmbeded: process.env.VUE_APP_EMBEDED,
      active: false,
      sidebar: false,

      groupOpened: false,
      genericToast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },

      menuSettings: [
        { path: '/cadastros-gerenciais/auditoria', title: 'Auditoria' },
        {
          path: '',
          title: 'eCAC',
          children: [
            { path: '/cadastros-gerenciais/ecac-process', title: 'Integração' },
            { path: '/cadastros-gerenciais/configuracoes-ecac', title: 'Mensagens' },
          ],

          active: false,
        },
        {
          path: '',
          title: 'Empresas',
          children: [
            { path: '/cadastros-gerenciais/empresas', title: 'Empresas' },
            { path: '/cadastros-gerenciais/estabelecimentos', title: 'Estabelecimentos' },
            { path: '/cadastros-gerenciais/grupos', title: 'Grupos' },
          ],
        },
        {
          path: '',
          title: 'e-Social',
          children: [
            { path: '/cadastros-gerenciais/upload-arquivo', title: 'Importação' },
            { path: '/cadastros-gerenciais/e-social/tables', title: 'Tabelas' },
            { path: '/cadastros-gerenciais/e-social/analysis-parameters', title: 'Parâmetros de análise' },
          ],
        },
        { path: '/cadastros-gerenciais/usuarios', title: 'Usuários' },
        { path: '/cadastros-gerenciais/cadastro-de-documentos', title: 'Documentos' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      adminMenus: 'user/getAdminMenus',
      moduleMessage: 'user/getModulo',
      userMenu: 'user/getMenus',
      userInfo: 'user/userInfo',
      pendencyOrNot: 'user/getIfPendency',
      hasTotalizers: 'user/hasTotalizers',
      thumbnail: 'user/getUserThumbnail',
      toastCertidoes: 'notifications/getShowToastCertidoes',
      toastTotalizers: 'notifications/getShowToastTotalizers',
      authorities: 'user/userAuthorities',
    }),

    returnLabel() {
      return process.env.VUE_APP_TAGFUNC;
    },

    returnMessage: {
      get: function () {
        return this.moduleMessage;
      },
      set: function (value) {},
    },
    returnMenus() {
      return menu;
    },
  },

  created() {
    this.init();
    this.$watch(
      () => this.$route.params,
      () => {
        let routeChoosed = this.$route.params.todos;

        if (routeChoosed === 'cedidos') {
          this.setTitle('Cessão');
          this.setTab('cedidos');
        } else if (routeChoosed === 'afastados-e-inativos') {
          this.setTitle('Afastados e inativos');
          this.setTab('afastados-e-inativos');
        } else if (routeChoosed === 'todos') {
          this.setTitle('Todos');
          this.setTab('todos');
        }
      },
    );
    // this.isNotAdmin();
  },

  beforeMount() {
    this.init();
  },

  updated() {},

  methods: {
    ...mapActions('notifications', ['changeShowToastCertidoes', 'changeShowToastTotalizers'], 'user', ['getUser']),
    ...mapActions('user', ['getUser']),

    ...mapActions('esocial/esocialMain', ['setTitle', 'setTab']),
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    hasMenuAccess(roles) {
      return true;
      // if (roles) {
      //   return roles.some((role) => this.authorities.includes(role));
      // } else {
      //   return true;
      // }
    },

    controlActiveTab() {
      let routeChoosed = this.$route.params.todos;
      if (routeChoosed === 'cedidos') {
        this.setTitle('Cessão');
        this.setTab('cedidos');
      } else if (routeChoosed === 'afastados-e-inativos') {
        this.setTitle('Afastados e inativos');
        this.setTab('afastados-e-inativos');
      } else if (routeChoosed === 'todos') {
        this.setTitle('Todos');
        this.setTab('todos');
      }
    },

    disparar(e) {
      if (e.title === 'War Smart Solutions') {
        this.active = true;
      } else {
        this.active = false;
      }
      if (e.title === 'eSocial') {
        const btnActive = this.$el.querySelector(`#${e.title}`);
        btnActive.style.backgroundColor = '#6753aa';
      }
    },

    dispararSubmenu(e) {
      this.disparar(e);
      this.groupOpened = false;
    },

    closePendentCndsMsg() {
      store.dispatch('user/confirmPendentsCndsMsg');
    },

    async init() {
      this.getUser();
      eventBus.$on('openToast', (data) => {
        this.openToast(data.message, data.type, data.topToast);
      });
      let hasAdmin = '';
      if (this.userInfo.authorities != undefined) {
        hasAdmin = this.userInfo.authorities.filter((n) => n === 'ROLE_ADMIN');
      }
      // else {
      //   this.getUser();
      // }
      if (hasAdmin.length > 0) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }

      if (this.pendencyOrNot && this.isAdmin === false && this.hasCertidoesRole()) {
        this.openToastCertidoes(
          `Existem pendências fiscais! Acesse o menu “Certidões” ou
        <a style="font-weight: 900" href="certidoes">clique aqui </a>para mais detalhes.`,
          'info',
          78,
        );
      }

      if (this.hasTotalizers) {
        if (this.pendencyOrNot && this.isAdmin === false) {
          this.openToastTotalizers(
            `Novos totalizadores disponíveis!</strong> Entre em contato com suporte@agnes.com.br para mais
              informações.`,
            'info',
            156,
          );
        } else {
          this.openToastTotalizers(
            `Novos totalizadores disponíveis!</strong> Entre em contato com suporte@agnes.com.br para mais
               informações.`,
            'info',
            78,
          );
        }
      }
    },

    hasCertidoesRole() {
      let hasCertidoesRole = '';
      if (this.userInfo.authorities != undefined) {
        hasCertidoesRole = this.userInfo.authorities.filter((n) => n === 'ROLE_MENU_CERTIDOES');
      } else {
        this.getUser();
      }

      return hasCertidoesRole.length > 0;
    },

    logout() {
      sessionStorage.clear();
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      this.$root.$emit('closeHubspotWidget');
      window.location.href = '/front-end/login';
    },

    close() {
      let message = null;
      store.dispatch('user/setMessage', message);
    },

    openToast(message, type, topToast) {
      this.genericToast.message = message;
      this.genericToast.type = type;
      this.genericToast.topToast = topToast;
      this.genericToast.show = true;
    },

    openToastTotalizers(message, type, topToast) {
      let toastTotalizers = { show: true, type: type, message: message, topToast: topToast };

      this.changeShowToastTotalizers(toastTotalizers);
    },

    openToastCertidoes(message, type, topToast) {
      let toastCertidoes = { show: true, type: type, message: message, topToast: topToast };

      this.changeShowToastCertidoes(toastCertidoes);
    },

    closeToastTotalizers() {
      let toastTotalizers = { show: false, message: '', typeToast: '', topToast: '' };
      this.changeShowToastTotalizers(toastTotalizers);
    },

    closeToastCertidoes() {
      let toastCertidoes = { show: false, message: '', typeToast: '', topToast: '' };
      this.changeShowToastCertidoes(toastCertidoes);
    },

    closeToast() {
      this.genericToast = { show: false, message: '', typeToast: '', topToast: '' };
    },
  },
};
</script>
<style scoped>
.app-toolbar >>> .v-toolbar__content {
  padding: 0;
  background-color: var(--v-menuBackground-base) !important;
}
.app-toolbar .v-btn {
  font-weight: 400px !important;
}
.app-toolbar-links .v-tabs > .v-slide-group.v-tabs-bar,
.app-toolbar-links .v-tabs > .v-slide-group.v-tabs-bar {
  background-color: inherit;
  height: inherit;
}
.logo {
  vertical-align: middle;
}
.v-dialog--active {
  box-shadow: none !important;
}
.message {
  margin-top: 60px;
  background-color: #e1e8f2 !important;
  font-size: 16px;
  color: #002761;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 771px;
  height: 56px;
  border: 1px solid #2d6796;
  border-radius: 8px;
  left: 567px;
  text-align: center !important;
}

.material-symbols-outlined {
  color: white;
}
.v-menu__content {
  border-radius: 0px 0px 4px 4px !important;
}

.button-size {
  font-size: 22px !important;
  font-weight: 300;
  max-width: 33px;
  max-height: 33px;
}
.normalButtons {
  background-color: var(--v-menuBackground-base) !important;
}
::v-deep .wssButton {
  background-color: var(--v-menuWssBackground-base) !important;
  height: 50px !important;
  border-radius: 4px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
#Analytics {
  border-radius: 4px !important;
}
.v-list-group--active {
  background-color: #ece7f5;
  border-bottom: 2px solid #ece7f5;
}
.v-list-group--active .v-list-item {
  background-color: #f7f7fd;
}
.v-btn--icon {
  color: var(--v-textColor-base) !important;
}
</style>
