<template></template>
<script>
import { mapState } from 'vuex';
import interactionService from '@/services/interactionService.js';
export default {
  beforeMount: function () {
    this.prepareVisitor();
    this.prepareCloseEvent();
  },
  computed: {
    ...mapState('user', {
      user: (state) => state.user,
    }),
  },
  methods: {
    async prepareVisitor() {
      var email = this.getUserMail();
      document.defaultView.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: await this.getHsToken(email),
      };
    },
    getUserMail: function () {
      return { ...this.user }.emailAddress;
    },
    async getHsToken(email) {

      try {
        var response = await interactionService.visitor.getToken(email);
        var token = response.data;
        var expire = new Date();
        expire.setHours(expire.getHours() + 12);
        var data = '{ "token": "' + token + '", "expire": ' + expire.getTime() + ' }';
        localStorage.setItem('hs_visitor_data', data);
      } catch (error) {
        console.error(error);
      }
      this.loadWidget();
      return token;
    },
    loadWidget() {
      var scriptLink = 'https://js-na1.hs-scripts.com/19537066.js';
      var first = 0;
      if (!document.querySelectorAll('script[src="' + scriptLink + '"]')[first]) {
        var hubspotScript = document.createElement('script');
        hubspotScript.setAttribute('src', scriptLink);
        document.head.appendChild(hubspotScript);
      } else {
        window.HubSpotConversations.widget.load();
      }
    },
    prepareCloseEvent() {
      this.$root.$on('closeHubspotWidget', () => {
        window.HubSpotConversations.widget.remove({resetWidget:true});
      });
    },
  },
};
</script>
